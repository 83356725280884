/*
 * @Author: WYY 
 * @Description: 起始页面
 * @ModuleName: 起始页面
 * @Date: 2018-09-25 15:41:58 
 * @Last Modified by: qianjun
 * @Last Modified time: 2020-09-21 11:05:53
 */
<template>
    <div class="begin_t">
        <div class="header">
            <div class="left">
                <img class="headerImg" :src="AudioUrlPublic + '/'  + JSON.parse(queryConfig.logoData)[0]" alt="">
                {{queryConfig.systemName}}
            </div>
            <div class="reight">
                <img class="headerImg" src="../assets/close2.png" alt=""  @click="handleClickCloseProgram">
            </div>            
        </div>
        <div class="begin_s">
            <div class="left"></div>
            <div class="reight">
                <div style="height:13%"></div>
                <p class="enTitle">Welcome to study</p>
                <div style="height:7%"></div>
                <h2 class="sourceName"  style="height:20.5%">{{CourseInfo.sourceName}}</h2> 
                <div style="height:9%"></div>
                <!-- <h2>Let's start learning happily.</h2> -->
                <div class="gotostudyBtnBox"
                 v-loading="loading"
                 element-loading-background="rgba(0, 0, 0, 0)">
                    <div class="gotostudyBtn"  @click="gotostudy()">

                    </div>
                </div>
                <div style="height:20%"></div>
                <div class="about" @click="ashow()">Understanding Software</div>
            </div>
        </div>
        <about :show.sync="show" :info='CourseInfo'></about>
        <p class="gsName">  
            {{queryConfig.name}}@版权所有 
        </p>
    </div>
</template>

<script>
    import about from './Aboutsoftware.vue'
    import {Url} from '@/util'
    export default {
        data(){
            return{
                show:false,
                check:false,
                CourseInfo:{},
                AudioUrlPublic:"",
                loading: false,//加载状态
                flag: false,//是否存在menulist
                isGo: false//是否点击跳转按钮
            }
        },
        components:{
            about
        },
        computed:{
            curriculum:function(){
                return this.$store.state.common.curriculum;
            },
            queryConfig:function(){
                
                return this.$store.state.common.queryConfig;
            },
            menulist:function() {
                return this.$store.state.common.menulist
            }
        },

        watch: {
            menulist: {
                handler(n, o) {
                    if(n && n.length) {
                        this.flag = true
                        this.loading = false
                    }
                    console.log(this.flag, this.isGo)
                    if(this.flag && this.isGo) {
                        sessionStorage.setItem('targetceng',n[0].ceng);
                        this.$router.push('/home/describe?pid=' + n[0].pid);
                    }
                    return n
                },
                immediate: true,
                deep: true
            }
        },
        async created() {
            this.AudioUrlPublic = Url.AudioUrlPublic
            this.getinfo();
            let media = await navigator.mediaDevices.enumerateDevices();
            media.forEach(el=>{
                if(el.kind=='audioinput'&&el.label!='默认'&&el.label!='通讯'){
                    this.check = true;
                }
            })
        },
        methods:{
            handleClickCloseProgram(){
                console.log("关闭：",this.$ipcRenderer);
                if(this.$ipcRenderer){
                    this.$ipcRenderer.send("exit");
                }
            },
            async getinfo(){
                let id = this.$route.query.id || this.curriculum || sessionStorage.getItem('curriculum');
                let url_ = await this.AjaxApi.getCourseInfo(id);
                this.CourseInfo = url_.data;
            },
            ashow(){
                this.show = true;
            },
            
            async gotostudy(){
                this.loading = true
                this.isGo = true 
                // if(!this.check){
                //     this.$message.warning('未检测到录音设备,请先安装设备在进行学习!');
                //     return false;
                // }
                 //取消套餐，修改为永久课时
                let url_ = {
                    data:1000*86400*366
                }
                sessionStorage.setItem('usertype',3);
                this.$store.commit('setitem',{'name':'usertype','item':3});
                let time = parseFloat(url_.data)/1000;
                sessionStorage.setItem('alltime',time);
                this.$store.commit('setitem',{'name':'RemainingTime','item':parseFloat(time)});
               
                sessionStorage.setItem('begin','true');
                this.$store.commit('setitem',{'name':'begin','item':true});
                this.ProductUuid(); 
                let list = this.$store.state.common.menulist;
                console.log(list,456); 
                if (list != null && list != undefined && list.length > 0) { 
                    if(list[0].allSectionModel == 3){ // 测试模式
                        let checkInfo = JSON.parse(sessionStorage.checkInfo)
                        if( !checkInfo.audioOutput){
                            this.$message.warning('无音频输出设备!');
                            return
                        }else if(!checkInfo.audioInput){
                            this.$message.warning('无音频输入设备!');
                            return
                        }
                        let starttime = new Date();
                        starttime = window.formatDateTime(starttime);
                        sessionStorage.setItem('starttime',starttime);
                        let testRequire = await this.AjaxApi.testRequire();
                        sessionStorage.setItem('rid',testRequire.data);
                    }
                
                    sessionStorage.setItem('checkid',list[0].id);
                    sessionStorage.setItem('targetceng',list[0].ceng);
                    this.$store.commit('setitem',{'name':'checkid','item':list[0].id})
                    this.$store.commit('setitem',{'name':'outerId','item':list[0].id});
                    this.$store.commit('setitem',{'name':'dataLayer','item':list[0].ceng})
                    this.$store.commit('setContinueStudy') // 2020-09-21
                    this.$router.push('/home/describe?pid=' + list[0].pid);
                }
            },
        }
    }
</script>

<style scoped lang="less">
.gsName{
    position: absolute;
    bottom: 0px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 26px;
    width: 100%;
    text-align: center;
}
.header{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 2px;
    align-items: center;
    box-shadow: 0px 3px 5px #ccc;
    .left{
        display: flex;
        align-content: center;
    }
    .headerImg{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
</style>

